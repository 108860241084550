import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { UserContext } from 'contexts/user-context'
import { useAuth } from 'hooks/use-auth'
import firebase from 'config/firebase'

import AlertBar from 'components/AlertBar'

import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import Portal from 'pages/Portal/Portal'
import PortalOpen from 'pages/PortalOpen/PortalOpen'

const App = () => {
  const { user, logout, initializing } = useAuth()

  firebase.analytics()

  let routes

  routes = (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          window.location = 'https://plynth.com'

          return <></>
        }}
      />

      <Route restricted={false} path="/:username/open" exact>
        <PortalOpen />
      </Route>

      <Route restricted={false} path="/:username">
        <Portal />
      </Route>

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )

  return (
    <UserContext.Provider
      value={{ user: user, logout: logout, initializing: initializing }}
    >
      <Router>
        <AlertBar />
        {routes}
      </Router>
    </UserContext.Provider>
  )
}

export default App
