import React from 'react'
import { Grid, Box, AppBar, Toolbar, styled } from '@mui/material'

import { HashLink } from 'react-router-hash-link'
import plynthLogo from 'images/plynth_logo_color.svg'

const StyledLogo = styled('img')({
  opacity: `${props => props.opacity || 1}`,
  width: '100%',
  maxWidth: '80px',
  height: '100%',
  objectFit: 'contain',
  objectPosition: '50% 50%',
  '&:hover': {
    opacity: 0.7,
  },
})

const WebsiteNavBar = ({ left, right, position, opacity }) => {
  return (
    <AppBar position="absolute" top="0" color="transparent" elevation={0}>
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
        >
          {!!left ? (
            left
          ) : (
            <Box flexGrow={1}>
              <Grid container>
                <Grid item>
                  <HashLink smooth to="/">
                    <Grid container direction="column" alignItems="center">
                      <StyledLogo
                        opacity={opacity}
                        src={plynthLogo}
                        alt="Plynth Logo"
                      />
                    </Grid>
                  </HashLink>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default WebsiteNavBar
